* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: white;
  width: 100%;
  min-height: 100vh;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  background-color: black;
  box-shadow: 0px 2px 0px;
  z-index: 1234;
}

header img {
  height: 80%;
  border-radius: 50%;
  border: 2px solid red;
}

footer ul li {
  padding: 8px;
  transition: 0.3s ease;
  margin-right: 20px;
  border-radius: 50%;
  border: 2px solid;
  background-color: #333;
}

footer ul li:hover {
  scale: 1.2;
}

footer h3 {
  text-transform: capitalize;
  font-size: 30px;
  margin-top: 40px;
}

footer ul li {
  padding: 5px;
  transition: 0.3s ease;
  margin-right: 10px;
  border-radius: 50%;
  background: none;
}
footer ul li a {
  font-size: 30px;
  color: black;
}
footer ul li:hover {
  border: 2px solid black;
}

#youtube:hover {
  background-color: red;
}

#youtube:hover a {
  color: white;
}

#insta:hover {
  background-color: #d841a3;
}

#insta:hover a {
  color: white;
}
#facebook:hover {
  background-color: #0866ff;
}

#facebook:hover a {
  color: white;
}

#mail:hover {
  background-color: #eb4132;
}

#mail:hover a {
  color: white;
}

#linkedin:hover {
  background-color: #0077b7;
}

#linkedin:hover a {
  color: white;
}
.diamond {
  --size: 50px;
  --quarter-size: calc(var(--size) / 4);

  border-style: solid;
  border-color: transparent transparent #333 transparent;
  border-width: 0 var(--quarter-size) var(--quarter-size) var(--quarter-size);
  height: 0;
  width: calc(var(--size) / 2);
  box-sizing: content-box;
  position: relative;
  margin: 0 0 calc(var(--size) / 2) 0;
}

.diamond:after {
  content: "";
  position: absolute;
  top: var(--quarter-size);
  left: calc(var(--quarter-size) * -1);
  width: 0;
  height: 0;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  border-width: calc(var(--size) * 0.7) calc(var(--size) / 2) 0
    calc(var(--size) / 2);
}

/* form css */

#form {
  margin: 0 20px;
}
.form {
  margin: 0 4rem !important;
}

.from-left {
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.from-right {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.from-left,
.from-right {
  transition: opacity 250ms ease-in, -webkit-transform 400ms ease-in;
  transition: opacity 250ms ease-in, transform 400ms ease-in;
  transition: opacity 250ms ease-in, transform 400ms ease-in,
    -webkit-transform 400ms ease-in;
  opacity: 0;
}

.from-left.appear,
.from-right.appear {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

.contact-form {
  margin: 20px 0;
  /* border-radius: 15px; */
}

.form h2 {
  text-align: center;
}

.form .img-container {
  margin-top: 20px;
  /* margin : auto; */
  width: fit-content;
}

.form .img-container img:hover {
  background-color: #d92027;
  scale: 1.2;
}

.form .img-container img {
  transition: 0.2s;
  padding: 10px;
  width: 50px;
  height: 50px;
  /* border       : 1px solid rgba(255, 255, 255, 0.24); */
}

.form .img-container a {
  height: 100%;
  width: 100%;
  border: none;
}

.form {
  display: grid;
  /* display              : none; */
  grid-template-columns: 1fr 1fr;
  margin-top: 50px;
  padding: 20px;
  background-color: #202020;
  /* background-image: url(./asstes/—Pngtree—red\ line\ background\ on\ black_1214451.jpg); */
  border-radius: 5px;
  column-gap: 20px;
}

.form .left {
  margin: auto;
  color: var(--form-text);
}

@media screen and (max-width: 800px) {
  .form .left {
    margin: 0;
  }

  .form {
    grid-template-columns: 1fr;
  }
}

.form .left h2 {
  font-family: "Poppins", sans-serif;
}

.form .left p {
  font-family: var(--form-font);
  line-height: 25px;
  max-width: 95%;
  font-weight: 300;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.74);
}

.form p {
  text-align: left;
  font-family: var(--form-font);
  letter-spacing: 1px;
  padding-left: 2px;
  /* color         : var(--form-text); */
}

.form input {
  border-radius: 5px;
  font-family: var(--form-font);
  background-color: transparent;
  color: white;
  border-bottom: 1px solid var(--form-border);
  box-shadow: none;
}

.form input::placeholder,
.form textarea::placeholder {
  color: var(--form-placeholder);
}

.form textarea {
  font-family: var(--form-font);
  background-color: transparent;
}

.form-input-container {
  margin: 20px 0;
}

.form-input-container span {
  display: inline-block;
  margin-left: 8px;
  font-size: 16px !important;
  margin-top: 10px;
}

.form-input-container .subheading {
  margin: none !important;
}

.form-input-container input {
  /* -webkit-appearance: none; */
  color: var(--form-text);
  font-weight: 500;
  font-size: 16px;
}

.form-input-container input[type="text"],
input[type="tel"] {
  transition: 0.3s;
  -webkit-appearance: none;
}

/* 
.form-input-container input[type="radio"]: {
    background-color: #0f0f0f;
} */

.form-input-container input:focus,
.form-input-container input:hover,
.form-input-container textarea:hover {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  scale: 1.02;
}

.form-input-container input:focus {
  outline: none;
}

.form-input-container textarea {
  border-radius: 5px;
  transition: 0.3s;
  font-size: 17px;
  border: none;
  border-bottom: 1px solid var(--form-border);
  -webkit-appearance: none;
  font-weight: 500;
}

.form-input-container textarea:focus {
  scale: 1.02;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;

  outline: none;
}

.form-input-container.radio {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.form-input-container.radio label {
  color: var(--form-text);
  border: 1px solid var(--form-border);
}

.form-input-container.radio label {
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
}

.form .submit {
  width: 100%;
  border-radius: 5px;
  border: none;
  border: 1px solid rgba(255, 255, 255, 0.103);
  padding: 10px 0;
  font-size: 17px;
  font-family: var(--form-font);
  color: white;
  background-color: #c31c23;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.form .submit:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;

  scale: 1.02;
}

.form .submit:active {
  scale: 0.98;
}

.left h3 {
  font-size: 30px;
}
.right input {
  width: 100%;
  margin: 10px 0px;
  height: 40px;
  background-color: #333;
  color: #777676;
  padding: 0px 10px;
}
.right input:hover {
  border-bottom: 2px solid;
}

.left p {
  max-width: none !important;
}

@media screen and (max-width: 600px) {
  .left h3 {
    font-size: 30px;
    text-align: center;
  }
  .left p {
    text-align: center;
    font-size: 12px !important;
  }
  .right input {
    width: 100%;
    padding: 0px 5px;
    margin: 10px 0px;
    height: 40px;
  }
  .form {
    margin: 2rem 1rem !important;
    padding: 2rem 0px;
  }

  .form-control::placeholder {
    font-size: 12px;
  }

  .pullovertext h1 {
    font-size: 20px;
  }
}

.form-input-container {
  height: auto;
  padding: 5px 0px;

  margin: 0px;
}
.form-input-container textarea {
  width: 100%;
  color: #777676;
  background-color: #333;
  margin-bottom: 0px;
}

.select-container {
  background-color: #333;
}
select {
  background-color: #333;
  width: 100%;
  height: 40px;
}

/* Base styles for the form */
.form-input-container {
  position: relative;
  margin-bottom: 10px;
}

@media screen and (max-width: 600px) {
  .left p {
    text-align: center;
    margin-bottom: 20px;
  }
}

.form-control {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

/* Floating label styles */
.form-input-container label {
  position: absolute;
  top: 23px;
  left: 10px;
  font-size: 16px;
  color: #aaa;
  pointer-events: none;
  transition: all 0.3s ease;
  transform-origin: 0 0;
  transform: translateY(0);
}

.form-control:focus + label,
.form-control:not(:placeholder-shown) + label {
  top: -15px;
  left: 5px;
  font-size: 16px;
  color: white;
  transform: translateY(0);
  transition: 0.5s ease;
}

textarea:hover,
textarea:active {
  color: white;
  border-left: 2px solid;
  border-right: 2px solid;
}

#text-area-container {
  margin-bottom: 0px;
}

input {
  color: white !important;
  text-decoration: none;
}

.error-message {
  color: red;
  font-size: 0.875em;
  margin-top: 0.25em;
}

textarea {
  color: white !important;
}
textarea::placeholder {
  color: grey !important;
  font-size: 14px !important;
}
.form {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: 4px solid;
  border-bottom: 4px solid;
}

.form {
  margin: 0 5rem !important;
}

@media screen and (max-width: 600px) {
  .form {
    margin: 0 1rem !important;
  }
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 20px 0;

  text-align: center;
}

footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

footer ul li {
  margin: 10px;
  margin-bottom: 150px;
}

footer h3 {
  margin-bottom: 20px;
  font-size: 20px;
}

svg {
  width: 100%;
  height: 100%;
}
svg text {
  animation: stroke 3s forwards alternate;
  stroke-width: 2;
  stroke: white;
  font-size: 100px;
  font-family: "Roboto, sans-serif" !important;

  text-transform: uppercase;
  letter-spacing: 3px;
}
@keyframes stroke {
  0% {
    fill: rgba(72, 138, 204, 0);
    stroke: rgba(102, 38, 27, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(72, 138, 204, 0);
    stroke: rgba(102, 38, 27, 1);
  }
  80% {
    fill: rgba(72, 138, 204, 0);
    stroke: rgb(161, 61, 43);
    stroke-width: 3;
  }
  100% {
    fill: red;
    stroke: rgba(102, 38, 27, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.home-start {
  position: relative !important;
  width: 100%;
  top: 80px;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: space-between;

  padding: 2rem 0rem;
}

#newheader {
  position: fixed;
  z-index: 10;
  height: 80px;
  width: 100%;
}
.home-start-left {
  padding: 0 3rem;
}

.home-start-right {
  width: 100%;
}

.home-start-right img {
  height: 500px;
}
.home-start-left h3 {
  display: block;
  font: normal 400 1.4rem / 1.2 "Lato", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  margin-bottom: 2.2rem;
  padding-top: 1.8rem;
  color: #636363;
  text-align: left;
  margin-top: 2rem;
  letter-spacing: 1px;
}
.home-start-left h1 {
  font: normal 400 4.4rem / 1 "Merriweather";
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  line-height: 60px;
  font-size: 44px;
}
.mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  .home-start {
    position: relative !important;
    width: 100%;
    top: 80px;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .mobile {
    display: inline;
  }
  .home-start-left {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
    padding: 1rem 0rem;
    width: 100%;
  }

  .home-start-right {
  }
  .home-start-left h3 {
    font-size: 20px;
    margin-top: 0rem;
  }
  .home-start-left h1,
  .letter-pullup-container {
    text-align: center !important;
    font-size: 30px;
    margin-top: 0rem;
    line-height: 40px;
    letter-spacing: 1px;
  }

  .home-start {
    padding: 2rem 2rem;
  }
  .desktop {
    display: none;
  }
}

/* // animate */

.home-start-left {
  position: relative;
}

.home-start-left h3,
.home-start-left h1 {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 1s ease, transform 1s ease;
}

.home-start-left.animate h3,
.home-start-left.animate h1 {
  opacity: 1;
  transform: translateX(0px);
}

.letter-pullup-container {
  display: inline-flex;
  flex-wrap: wrap;
  font-size: 44px;
  overflow: hidden;
  white-space: pre;
  color: black;
  font-weight: 700;
  padding: 0 1rem;
}

.letter {
  display: inline-block;
}

.home-start-right {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.home-start-right img {
  width: 80%;
  height: auto;
  border-radius: 30px;

  object-fit: cover;
  border: 4px solid black;
}

@media screen and (max-width: 600px) {
  .home-start {
    position: relative !important;
    width: 100%;
    top: 80px;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .mobile {
    display: inline;
  }
  .home-start-left {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: start;
    padding: 1rem 0rem;
    width: 100%;
  }

  .home-start-right {
  }
  .home-start-left h3 {
    font-size: 20px;
    margin-top: 0rem;
  }
  .home-start-left h1 {
    font-size: 30px;
    margin-top: 0rem;
    line-height: 40px;
    letter-spacing: 1px;
  }
  .letter-pullup-container {
    font-size: 30px;
    padding: 0px;
    font-weight: 500;
  }

  .home-start {
    padding: 2rem 2rem;
  }
  .desktop {
    display: none;
  }
  .home-start-right {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  .home-start-right img {
    width: 100%;
    height: auto;
    margin-top: 10%;
    border-radius: 30px;
    /* border-right: 4px solid;
    border-bottom: 4px solid; */
    object-fit: cover;
    box-sizing: border-box;
  }
}

#form-button {
  margin-top: 4rem;
  outline: none;
  margin: none;
  padding: 0;
  border: none;
  background: none;
  padding: 0.5rem 1rem;
  font-size: 20px;
  border: 2px solid #333;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font: normal 400 1.4rem / 1.2 "Lato", sans-serif;
}

.home-start-left h1 {
  color: red;
}

@media screen and (max-width: 600px) {
  #form-button {
    margin-top: 2rem;
    padding: 0.5rem 0.5rem;
    font-size: 20px;
  }
}

.slide-in {
  animation: slideIn 1s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-button {
  animation: slideInButton 1s ease-out forwards;
}

@keyframes slideInButton {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.form {
  position: relative;
  top: 10rem;
  border-color: red;
  margin-top: 80px;
}
#newheader {
  position: fixed;
}
#newheader img {
  border: none;
  border-radius: 0px;
}

#footer {
  width: 100%;

  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 15rem;
  padding: 0;
  padding-bottom: 40px;
}
.button {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: none;
  background: linear-gradient(120deg, #833ab4, #fd1d1d, #fcb045);
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;

  margin: 0 0.5rem;
}

@media screen and (max-width: 600px) {
  #footer {
    padding: 0 1rem;
  }
  .button {
    margin: auto;
    width: 40px;
    height: 40px;
  }
  .form {
    position: relative;
    top: 10rem;
    border-color: red;
  }
}
.button svg {
  color: white;
  width: 30px;
  height: 30px;
  z-index: 9;
}

.button:nth-child(2) {
  background: linear-gradient(120deg, #02ff2c, #008a12);
}

.button:nth-child(3) {
  background: rgb(69, 187, 255);
}

.button:nth-child(4) {
  background: rgb(255, 33, 33);
}

.button:nth-child(6) {
  background: black;
}

.button:active {
  transform: scale(0.85);
}

.button::before {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #212121;
  border-radius: 50px;
  z-index: -1;
  border: 0px solid rgba(255, 255, 255, 0.411);
  transition: 0.4s;
}

.button:hover::before {
  width: 0px;
  height: 0px;
}

.form {
  margin-top: 0px !important;
  position: relative;
  top: 6rem;
  border-color: red;
  margin: 0px;
}

#conatct-formx {
  margin-top: 6rem;
  padding: 0px !important;
  box-sizing: border-box;
}

.social-heading {
  position: relative;
  color: black;
  top: 12rem;
  text-align: center;
}

.social-heading {
  margin-top: 2rem;
  font-size: 25px;
  line-height: 25x;
  text-transform: capitalize;
}
@media screen and (max-width: 600px) {
  footer button {
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 600px) {
  #footer {
    padding: 0 1rem;
    padding-bottom: 60px;
  }
  .button {
    margin: auto;
    width: 40px;
    height: 40px;
    margin: 0 0.5rem !important;
  }
  .form {
    position: relative;
    top: 6rem;
    border-color: red;
  }

  .button::before {
    height: 40px;
    width: 40px;
  }
}

#form-button a {
  text-decoration: none;
  color: #202020;
}

.home-start-left {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1s ease, transform 1s ease;
}
.home-start-left.animate {
  opacity: 1;
  transform: translateX(0);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.button svg {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.letter-pullup-container {
  padding: 0 0rem !important;
}

.form {
  margin: 0 9rem !important;
}

#headh1 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  font-style: normal;
  color: #c31c23 !important;
  text-align: left !important;
}

@media screen and (max-width: 600px) {
  .form {
    margin: 0 1rem !important;
  }
  #form-button a {
    font-size: 15px;
  }
  #headh1 {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
    color: #c31c23 !important;
    text-align: left !important;
  }
}

@media screen and (min-width: 600px) {
  .home-start-left {
    padding: 0 3rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  #form-button {
    width: fit-content;
    margin-top: 2rem !important;
  }
}

#loader {
  margin: 0px !important;
  padding: 0px !important;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}
#loadero {
  margin: 0px !important;
  padding: 0px !important;
  color: black;
}
